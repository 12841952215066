@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;200&display=swap');
:root {
  --color-body: #f2f2f1;
  --color-primario: #9d6d3c;
  --color-secundario: #68675c;
  --color-terciario: #929183;
  --color-cuaternario: #857b70;
  --color-quinto: #f8bc28;
  --color-black: #161616;
}

body {
  background-color: var(--color-body);
  margin: 0;
}

*{
font-family: 'Mada', sans-serif;;
margin: 0;
padding: 0;
} 

/* navbar */

.nav {
  min-width: 100%;
  background-color: var(--color-body);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 61px;
  z-index: 100;
}
.ul-nav {
  display: flex;
  flex-direction: row;
  list-style: none;
  cursor: pointer;
}
.li {
  color: inherit;
  text-decoration: inherit;
}

.logo {
  width: 300px;
  height: 100px;
  margin-left: -5px;
}
.toggle {
  display: none;
}
.navbar-links-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-links-container .link-nav {
  margin-right: 2rem;
  text-decoration: none;
  color: var(--color-secundario);
  font-size: 17px;
  font-weight: 350;
}
.navbar-links-container .link-nav:hover {
  color: var(--color-quinto);
}

/* Hero */

.container-img {
  display: flex;
  align-items: center;
  width: 86%;
  min-height: 700px;
  background-image: url(./Assets/mitchel-lensink-2mhIdAKEAPY-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  margin-right: 7%;
  margin-left: 7%;
}
.text-home {
  color: var(--color-body);
  font-size: 37px;
  margin: 0%;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 30px;
}
.separador {
  width: 100%;
  height: 4000px;
}
.cont-hero-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.cont-img-two {
  width: 100%;
  min-height: 600px;
  background-image: url(./Assets/325774404_721434212817141_2232866153152883640_n.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  /* background-attachment: fixed; */
  margin-top: 120px; 
  margin-bottom: 60px;
  margin-left: 14%;
  grid-column: 1 / 2;
}
.cont-text-two {
  grid-column: 2 / 3;
  margin-top: 200px;
  margin-left: 50px;
  margin-right: 70px;
}
.cont-text-two .parrafo {
  font-size: 17px;
  margin-top: 20px;
  color: #68675c;
  margin-right: 75px;
}
.cont-text-two h1 {
  color: var(--color-black);
}
.cont-text-two .txt-home-two {
  font-size: 23px;
}

/* buttons */
/* buttons-hero */
.btn-hero {
  background-color: var(--color-quinto);
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-top: 80px;
  margin-left: 80px;
}
.btn-hero-two {
  background-color: var(--color-black);
  border: none;
  width: 150px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  color: var(--color-body);
  margin-top: 70px;
  cursor: pointer;
}

/* acerca de */
.cont-img-acerca {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 700px;
  background-image: url(./Assets/laura-cleffmann-z3drmcczVTc-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
  margin-bottom: 80px;
}

.container-acerca {
  margin-top: 150px;
}

.cont-txt-acerca {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 22px;
}

.tituloAcercaDe{
  text-align: center;
}

.parrafo-acerca {
  padding: 40px 200px 70px 200px;
  font-size: 18px;
  text-align: center;
  font-weight: 350;
  color: var(--color-secundario);
  
}

.caja2,
.caja4,
.caja5,
.caja7 {
  background-color: #d8d8d7;
}

.caja1 {
  background-image: url(./Assets/james-kovin-YQGPSblLPz0-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.caja3 {
  background-image: url(./Assets/krakenimages-376KN_ISplE-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -50px;
}

.caja6 {
  background-image: url(./Assets/spencer-davis-SVfXlChg9HI-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.caja8 {
  background-image: url(./Assets/spencer-davis-QTKwYmMumfk-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.h-caja {
  padding: 60px 60px 30px 60px;
}

.p-caja {
  padding: 10px 60px 30px 60px;
  color: var(--color-secundario);
}

.grid-fotos {
  display: grid;
  /* flex-wrap: wrap; */
  grid-template-columns: 3fr 3fr 3fr 3fr;
  width: 100%;
  min-height: 600px;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 80px;
}

.p-enviado{
  margin-bottom: 20px;
}

/* mediaq */
@media (max-width: 1300px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .cont-text-two {
    margin-left: 110px;
  }
}
@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .parrafo-acerca{
    padding: 40px 5% 70px 5%;
  }
 
}

/* @media (max-width: 1150px){
  .cont-text-two {
    margin-top: 180px;
    min-height: 1000px;
  }
} */
@media (max-width: 1077px){
  .lineas{
    margin-top: 200px;
  }

}

@media (max-width: 912px) {
  .cont-text-two {
    min-height: 1000px;
  }
  .cont-img-two {
    height: 500px;
  }
}
@media (max-width: 820px) {
  .nav-logo-container {
    max-width: 130px;
  }
  .navbar-links-container {
    position: absolute;
    top: 80px;
    left: 0;
    background-color: var(--color-body);
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.1s ease-out;
    transform: translateX(-100%);
    height: 500px;
    gap: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .navbar-links-container.open {
    transform: translateX(0) !important;
  }

  .navbar-links-container.open .link-nav {
    font-size: 20px;
  }
  .toggle {
    display: flex !important;
    flex-direction: column;
    margin: 15px;
  }
  .ul-nav {
    flex-direction: column;
    gap: 40px;
    padding-top: -20px;
  }

  .toggle span {
    width: 25px;
    height: 2px;
    background: var(--color-primario);
    margin-bottom: 5px;
    border-radius: 2px;
    transform-origin: 5px 0px;
    transition: all 0.2s linear;
  }

  .toggle.open > span {
    transform: rotate(45deg) translate(0px, 0px);
    width: 28px;
  }
  .toggle.open > span:nth-child(2) {
    display: none;
  }
  .toggle.open > span:nth-child(3) {
    transform: rotate(-45deg) translate(-5px, 3px);
    width: 28px;
  }
  .text-home {
    font-size: 30px;
  }
  .container-img {
    width: 85%;
  }
  .cont-img-two {
    margin-top: 50px;
    min-height: 600px;
  }
  .cont-text-two {
    margin: 0%;
    padding-left: 70px;
    padding-right: 20px;
    padding-top: 130px;
  }
  .cont-text-two .parrafo {
    font-size: 17px;
    margin-top: 10px;
    color: #68675c;
    margin-right: 50px;
  }
  .cont-text-two .txt-home-two {
    font-size: 17px;
  }
  .btn-hero-two {
    margin-top: 30px;
  }

  .grid-fotos{
    grid-template-columns: 6fr 6fr;
  }

}
@media (max-width: 650px) {
  .text-home {
    margin: 0%;
    padding-left: 10px;
    padding-right: 10px;
  }
  /* .cont-text-two {
    margin: 0%;
    padding-left: 70px;
    padding-right: 20px;
    padding-top: 130px;
  } */
}
@media (max-width: 600px) {
  .btn-hero-two {
    padding: 8px 5px;
    font-size: 13px;
    margin-top: 30px;
  }
  /* .cont-text-two {
    padding-top: 80px;
  } */
}
@media (max-width: 550px) {
  .btn-hero-two {
    padding: 8px 5px;
    font-size: 13px;
    margin-top: 10px;
  }
  /* .cont-text-two {
    margin-left: 0px;
    padding-top: 90px;
  }
  .cont-text-two .parrafo {
    margin-top: 4px;
  } */
}





@media (max-width: 514px){
  .beneficios{
    margin-top: -50px;
  }
  .tituloLineas{
    margin-top: -514px;
  }
} 

@media (max-width: 350px) {
  .logo {
    width: 200px;
    height: 70px;
    margin-left: -5px;
  }
  .text-home {
    padding-top: 0px;
    font-size: 20px;
  }
  .cont-img-two {
    display: none;
  }
  .cont-hero-two {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cont-text-two {
    padding-right: 0px;
    padding-top: 50px;
    padding-left: 20px;
  }
  .btn-hero {
    margin-top: 10px;
    margin-left: 10px;
  }
  .container-img {
    width: 100%;
    margin-right: 0%;
    margin-left: 0%;
  }

}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600;200&display=swap");

/* Beneficios */

/* benefits */
.beneficios {
  padding-bottom: 70px;
}

.divTitulo {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titulo {
  line-height: 1.35em;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.tarjetas {
  /* width: 100%; */
  height: 250px;
  display: flex;
  justify-content: center;

  /* padding-left: 50px;  */

  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  gap: 50px;
  
}

/* tarjeta */
.divTarjeta {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* padding-right: 100px; */
}

.tituloTarjeta{
  font-family: 'Poppins', sans-serif;
  color: #A26028;
  
  font-size: 22px;
  line-height: 1.41em;
  margin-bottom: 20px;
  text-align: center;
}

.divTituloTarjeta {
  width: 170px;
}

.divDescripcion {
  height: auto;
  width: 232px;

  display: flex;
  justify-content: center;
  text-align: center;
}

.descripcion {
  font-family: "ExtraLight", sans-serif;

  color: rgb(135, 135, 135);

  line-height: 1.7em;

  background: transparent;
  font-size: 15px;
}

/* Lineas */

/* lineas */
.lineas {
  background-color: rgb(239, 239, 239);
}

.tituloLineas {
  line-height: 1.35em;
  text-align: center;
  font-size: 30px;
  /* font-weight: bold; */

  font-family: "Poppins", sans-serif;
  background: transparent;

  padding: 80px 0px 10px;
}

.lineasImagen {
  display: flex;
  margin: 0 auto;
  position: relative;

  justify-content: center;
  align-items: center;

  padding: 40px;

  gap: 50px;
}

/* linea */
.imagenLinea {
  width: 450px;
  height: 726px;

  background-size: cover; /* Ajusta la imagen al tamaño del div sin distorsionarla */
  background-position: center; /* Centra la imagen en el div */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  z-index: 1;
}

.text-overlay {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.descLinea {
  text-decoration: none;
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 22px;
  letter-spacing: normal;
  cursor: pointer;
  line-height: 1.41em;
  color: white;

  font-weight: bold;

  font-family: "Poppins", sans-serif;
}

/* boton */
.divBoton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px 80px;
}

.boton {
  font-family: "ExtraLight", sans-serif;

  background-color: rgb(30, 30, 30);
  color: rgb(218, 217, 217);
  padding: 10px;
  border: none;
  border-radius: 0;
  text-decoration: none;

  /* font-size: 1.1rem; */
  cursor: pointer;

  transition: background-color 0.3s ease;
}

.boton:hover {
  background-color: rgb(162, 96, 40);
}

/* Testimonios */

/* testimonios */
.divTestimonio {
  background-color: rgb(245, 245, 245);
}

.divDescTestimonio {
  display: flex;
  margin: 0 auto;
  position: relative;
  align-items: center;
  justify-content: center;

  gap: 150px;
}

/* testimonio */
.testimonio {
  text-align: center;
  /* width: 200px; */

  /* display: flex; */
  /* flex-direction: column; */

  position: static;
  display: grid;
  height: auto;
  width: 220px;
  height: 481px;
  grid-template-rows: repeat(3, min-content) 1fr;
  grid-template-columns: 100%;
}

.comillaTestimonio {
  height: 30px;
}

.descTestimonio {
  line-height: 1.88em;
  text-align: center;
  font-size: 15px;

  color: rgb(149, 149, 149);
  font-family: "ExtraLight", sans-serif;

  padding-top: 40px;
}

.nombreTestimonio {
  font-family: "Poppins", sans-serif;
  color: rgb(162, 96, 40);
  font-weight: bold;
  background: transparents;
  font-size: 20px;
  padding-top: 30px;
}

/* Servicios */

/* LineasProductos */
.divLineasProductos {
  margin-top: 15%;
  padding-bottom: 100px;
}

.divTituloLineas {
  padding-bottom: 20px;
}

/* LineaProductos */
.divImagenesLinea {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.subTitulo {
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  font-weight: 100;
  font-size: 28px;
  line-height: normal;
  text-align: center;

  font-family: "ExtraLight", sans-serif;
  color: rgb(30, 30, 30);

  padding-bottom: 25px;
}

.imagenLineaProductos {
  background-size: cover;
  height: 250px;
  width: 400px;
}

.imagenLineaProductos:hover {
  filter: brightness(80%);
}

.descImagen {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding-top: 50px;
}

.divLineaInterior {
  padding-bottom: 100px;
}

/* LinkWPP */
.divLinkWPP {
  width: auto;
  /* width:100%; */
  height: 200px;
  background-color: #1e1e1e;

  padding: 30px;

  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

.divTituloLinkWPP {
  /* text-align: center; */
  width: 450px;
}

.tituloLinkWPP {
  text-align: center;
  color: #f2f2f1;

  font-family: "Poppins", sans-serif;
  font-size: 30px;

  padding-top: 40px;
}

.botonSecundario {
  font-family: "ExtraLight", sans-serif;

  background-color: rgb(255, 255, 255);
  color: rgb(30, 30, 30);
  padding: 15px;
  border: none;
  border-radius: 0;
  text-decoration: none;

  /* font-size: 1.1rem; */
  cursor: pointer;

  transition: background-color 0.3s ease;
}

.botonSecundario:hover {
  color: #f8bc28;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* trabajos */
.container-img-trabajos{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 50px;
    justify-content: center;
    align-items: center;
  }
.trabajos-caja, .trabajos-caja2, .trabajos-caja3, .trabajos-caja4, .trabajos-caja5, 
.trabajos-caja6, .trabajos-caja7, .trabajos-caja8{
width: 400px;
height: 300px;
background-color: #9a9a9a;
}

.trabajos-caja{  
  background-image: url(./Assets/trabajos-caja.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.trabajos-caja2{  
  background-image: url(./Assets/trabajos-caja2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.trabajos-caja3{  
  background-image: url(./Assets/trabajos-caja3.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
.trabajos-caja4{  
  background-image: url(./Assets/trabajos-caja4.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
.trabajos-caja5{  
  background-image: url(./Assets/trabajos-caja5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.trabajos-caja6{  
  background-image: url(./Assets/trabajos-caja6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.trabajos-caja7{  
  background-image: url(./Assets/trabajos-caja7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.trabajos-caja8{  
  background-image: url(./Assets/laura-cleffmann-z3drmcczVTc-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

/* mediaq */ 

@media screen and (max-width: 2000px) {

  .cont-text-two{
    margin-left: 142px;
  }}
@media screen and (max-width: 1300px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .cont-text-two{
    margin-left: 122px;
  }
  .tarjetas {
    flex-wrap: wrap; /* envuelve los elementos en varias filas */
    justify-content: center;
  }
  .beneficios {
    flex-wrap: wrap; /* envuelve los elementos en varias filas */
    
  }
  .divImagenesLinea {
    flex-wrap: wrap; /* envuelve los elementos en varias filas */
    justify-content: center;
  }

  /*   .elemento {
    width: calc(50% - 20px); /* divide el ancho en 2 filas y ajusta los márgenes 
  } */
}
@media screen and (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 912px) {
  .cont-text-two{
    margin-top: 80px; 
    min-height: 0px;
  }
  .cont-img-two{
    height: 500px;
  }
}
@media screen and (max-width: 820px) {
  .nav-logo-container {
    max-width: 130px;
  }
  .navbar-links-container {
    position: absolute;
    top: 80px;
    left: 0;
    background-color: var(--color-body);
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.1s ease-out;
    transform: translateX(-100%);
    height: 500px;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }
  .navbar-links-container.open{
    transform: translateX(0) !important;
  }

  .navbar-links-container.open .link-nav{
  font-size: 20px;
  }
  .toggle{
    display: flex !important;
    flex-direction: column;
    margin: 15px;
  }
  .ul-nav{
    flex-direction: column;
    gap: 40px;
    padding-top: -20px;
  }

  .toggle span{
    width: 25px;
    height: 2px;
    background: var(--color-primario);
    margin-bottom: 5px;
    border-radius: 2px;
    transform-origin:5px 0px;
    transition: all 0.2s linear;
  }
  
  .toggle.open > span{
    transform: rotate(45deg) translate(0px, 0px);
    width: 28px;
  }
  .toggle.open > span:nth-child(2){
    display: none;
  }
  .toggle.open > span:nth-child(3){
    transform: rotate(-45deg) translate(-5px, 3px);
    width: 28px;
  }
  .text-home{
    font-size: 30px;
  }
  .container-img {
    width: 85%;
  }
  .cont-img-two{
  
    min-height: 400px;
  }
  .cont-text-two{
    margin: 0%;
    padding-left: 70px;
    padding-right: 20px;
    padding-top: 130px;

  }
  .cont-text-two .parrafo{
    font-size: 17px;
    margin-top: 10px;
    color: #68675C;
    margin-right: 50px;
  
  }
  .cont-text-two .txt-home-two{
    font-size: 17px;
  }
  .btn-hero-two{
    margin-top: 30px;
  }
}
@media (max-width: 720px) {
  .tarjetas {
    flex-wrap: wrap; /* envuelve los elementos en varias filas */
    justify-content: center;
  }
  .beneficios {
    flex-wrap: wrap; /* envuelve los elementos en varias filas */
    padding-bottom: 700px;
  }

  .lineasImagen {
    flex-wrap: wrap; /* envuelve los elementos en varias filas */
    justify-content: center;
  }

  .divDescTestimonio {
    flex-wrap: wrap; /* envuelve los elementos en varias filas */
    justify-content: center;
  }
  .testimonio {
    width: 80%; /* establece el ancho al 100% para que los elementos se muestren en una columna */
    height: 50%;

    padding-bottom: 80px;
  }

  .imagenLinea {
    width: 450px;
    height: 300px;

    background-size: cover; /* Ajusta la imagen al tamaño del div sin distorsionarla */
    background-position: center; /* Centra la imagen en el div */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    z-index: 1;
  }

  .titulo {
    line-height: 1.35em;
    text-align: center;
    font-size: 30px;
    font-weight: bold;

    font-family: "Poppins", sans-serif;
  }
}

@media screen and (max-width: 690px) {
  .divLineasProductos {
    margin-top: 30%;
  }

  .divTituloLinkWPP {
    /* text-align: center; */
    width: 300px;
  }

  .tituloLinkWPP {
    font-size: 20px;
  }
}

@media (max-width: 650px){
.text-home{
  margin: 0%;
  padding-left: 10px;
  padding-right: 10px;
  }
  .cont-text-two{
    margin: 0%;
    padding-left: 70px;
    padding-right: 20px;
    padding-top: 130px;
  }
}
@media screen and (max-width: 600px){
.btn-hero-two{
  padding: 8px 5px;
  font-size: 13px;
  margin-top: 30px;
}

}
@media(max-width: 800px){
.beneficios {
  margin-top: -40px;
}}

@media(max-width: 550px){
  .btn-hero-two{
    padding: 8px 5px;
    font-size: 13px;
    margin-top: 10px;
  }
  .cont-text-two{
    margin-left: 0px;
    padding-top: 90px;
  }
  .cont-text-two .parrafo{margin-top: 4px;
  
  }}




@media  (max-width: 350px){
  .logo{
    width: 200px;
    height: 70px;
    margin-left: -5px;
}
.text-home {
  padding-top: 0px;
  font-size: 20px;
}
  .cont-img-two{
  display: none; 
}
  .cont-hero-two{
  display: flex;
  align-items: center;
  justify-content: center;
}
  .cont-text-two{
  padding-right: 0px;
  padding-top: 50px;
  padding-left: 20px;
}
.btn-hero{
  margin-top: 10px;
  margin-left: 10px;
  }
  .container-img {
    width: 100%;
    margin-right: 0%;
    margin-left: 0%;      
}
  .descLinea{
    font-size: 18px;
  }


  
@media  (max-width: 245px){
  .logo{
    width: 170px;
}
.text-home {
  font-size: 18px;
}
.lineas{
  margin-top: 0px;
}
.beneficios {
  flex-wrap: wrap;
  padding-bottom: 700px;

}
.cont-hero-two{
  margin-left: 20px;
}

}}















/* Componentes Nacho */

/* Empiezan Estilos footer */
.containerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 50px 20px;
  background-color: #f5f5f5;
  font-family: "ExtraLight", sans-serif;
  color: #9a9a9a;
  /* margin-top: 20px; */
}

.text-span {
  flex-basis: 60%;
  text-align: left;
  margin-bottom: 10px;
  text-decoration: none;
  background: transparent;
}

.social-span {
  flex-basis: 35%;
  text-align: right;
}

.social-span a {
  display: inline-block;
  margin-left: 10px;
}

.svg-item {
  width: 30px;
  height: 30px;
}

.a-PP {
  color: #9a9a9a;
  text-decoration: none;
}

.a.PP a:visited {
  color: #9a9a9a;
  text-decoration: none;
}
/* Terminan Estilos footer */

/* --------------- Formcontacto --------------- */
/* Form Contacto Negro */
.containerContactForm_negro {
  background-color: #1e1e1e;
  color: white;
  padding: 20px;
  gap: 40px;
  /* font-family: "ExtraLight", sans-serif; */
}

.contentContactForm {
  display: flex;
  justify-content: space-between;
}

.left-content {
  width: 40%;
  justify-content: center;
  padding-left: 50px; /* Ajusta el valor según tu preferencia */


}

.right-content {
  width: 40%;
  padding-right: 50px; /* Ajusta el valor según tu preferencia */
}

.h1Form_negro {
  font-family: "poppins-semibold", "poppins", sans-serif;
  color: #f5f5f5;
  background: transparent;
  letter-spacing: normal;
}

.cuerpoForm_negro {
  font-family: "poppins-extralight", "poppins", sans-serif;
  color: #c7c7c7;
  line-height: 1.88em;
  font-size: 15px;
  line-height: 40px;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  width: calc(
    50% - 10px
  ); /* Para tener un espacio de separación entre los campos */
  margin-bottom: 10px;
}

form label,
form input,
form textarea {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

form textarea {
  height: 100px;
}

form button {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.inputForm_negro {
  border: none;
  border-bottom: 2px solid #f5f5f57d;
  outline: none;
  background-color: transparent;
  color: white; /* Cambiamos el color del texto a blanco */
  caret-color: gray;
  font-size: medium;
  padding-left: 15px;
  padding-bottom: 5px;
}

.inputForm_negro:hover {
  border-bottom: 2px solid white;
}

.inputForm_negro:focus {
  border-bottom: 2px solid #ff4040;
}

.formLabels {
  font-family: "poppins-extralight", "poppins", sans-serif;
}

.formTextArea_negro {
  border: none;
  border-bottom: 2px solid #f5f5f57d;
  outline: none;
  background-color: transparent;
  color: white; /* Color del texto en blanco */
  caret-color: gray; /* Color del caret en gris */
  resize: none; /* Deshabilita la redimensionabilidad del textarea */
  height: 50px; /* Altura fija del textarea */
  font-family: "Poppins", sans-serif; /* Cambia "Poppins" por la fuente que desees utilizar */
}

.formTextArea_negro:hover {
  border-bottom: 2px solid white; /* Línea inferior blanca al pasar el mouse */
}

.formTextArea_negro:focus {
  border-bottom: 2px solid #ff4040; /* Línea inferior roja al enfocar */
}

.formButtonSubmit_negro {
  background-color: white;
  color: #4f4f4f;
  width: 100%;
  height: 40px;
  text-align: center;
  font-family: "poppins-extralight", "poppins", sans-serif;
}

/* Cierra Form Contacto Negro */

/* /* Form Contacto Blanco   */

.containerContactForm_blanco {
  background-color: white;
  color: #1e1e1e;
  padding: 20px;
  gap: 40px;
}

.h1Form_blanco {
  font-family: "poppins-semibold", "poppins", sans-serif;
  color: #000000;
  background: transparent;
  letter-spacing: normal;
}

.cuerpoForm_blanco {
  font-family: "poppins-extralight", "poppins", sans-serif;
  color: #1e1e1e;
  line-height: 1.88em;
  font-size: 15px;
  line-height: 40px;
}

.formButtonSubmit_blanco {
  background-color: #1f1f1e;
  color: #dbdbdb;
  width: 100%;
  height: 40px;
  text-align: center;
  font-family: "poppins-extralight", "poppins", sans-serif;
}

.formTextArea_blanco {
  border: none;
  border-bottom: 2px solid #0000007d;
  outline: none;
  background-color: transparent;
  color: white; /* Color del texto en blanco */
  caret-color: gray; /* Color del caret en gris */
  resize: none; /* Deshabilita la redimensionabilidad del textarea */
  height: 50px; /* Altura fija del textarea */
  font-family: "Poppins", sans-serif; /* Cambia "Poppins" por la fuente que desees utilizar */
}

.inputForm_blanco {
  border: none;
  border-bottom: 2px solid #0000007d;
  outline: none;
  background-color: transparent;
  color: #1e1e1e; /* Cambiamos el color del texto a blanco */
  caret-color: rgb(74, 74, 74);
  font-size: medium;
  padding-left: 15px;
  padding-bottom: 5px;
}

form button {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.inputForm_blanco:hover {
  border-bottom: 2px solid black;
}

.inputForm_blanco:focus {
  border-bottom: 2px solid #ff4040;
}

.formLabels {
  font-family: "poppins-extralight", "poppins", sans-serif;
}


.formTextArea_blanco:hover {
  border-bottom: 2px solid black; /* Línea inferior blanca al pasar el mouse */
}

.formTextArea_blanco:focus {
  border-bottom: 2px solid #ff4040; /* Línea inferior roja al enfocar */
}


/* Cierra Form Contacto Blanco */

/* Inicia Media queries */
@media (max-width: 550px) {
  .contentContactForm {
    flex-direction: column;
    align-items: center;
  }
  
  .left-content {
    order: 0;
    padding-left: 0;
    width: 80%;
    padding-bottom: 35px;
  }
  
  .right-content {
    order: 1;
    padding-right: 0;
    width: 80%;
  }
  
  .form-row {
    flex-direction: column;
  }
  
  .form-group {
    width: 100%;
  }
  
  form label,
  form input,
  form textarea {
    width: 100%;
  }
  
  form button {
    width: auto;
  }
}
/* Terminan media queries nacho  */
/* Cierre Componentes Nacho */

/* Sanmi Mapa */
.map-container{
  width: 70%;
  height: 80vh;
  margin: 0;
}

.contactoPage {
  margin-top: 100px;
}

.divMapa{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

/* Componentes Nacho */
/* buttonFixedWpp.jsx: */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #075e54cf;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}

.floating-button img {
  width: 40px;
  height: 40px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  .83% {
    transform: translateX(-5px) rotate(-5deg);
  }
  1.66% {
    transform: translateX(5px) rotate(5deg);
  }
  2.5% {
    transform: translateX(-5px) rotate(-5deg);
  }
  3.33% {
    transform: translateX(0);
  }
  100%{

  }

}

.shake {
  animation: shake 30s ease-in-out infinite;
}

/* validaciones form */
.error-message {
  margin-left: 5px;
  color: red;
  font-size: 14px;
  margin-top: 5px;
}



/* Termina buttonFixedWpp.jsx */
@media  (max-width: 430px){
  
  .cont-img-two{
    display: none; 
  }
  .cont-hero-two{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .beneficios{
    margin-top: 50px;
  }

  .tarjetas{
    gap: 0px;
  }
  .lineas{
    margin-top: 300px;
  }
}

@media (max-width: 600px) {
  .cont-img-two {
    width: 100%;
    min-height: 500px;
    background-attachment: scroll;
    margin-top: 154px;
  } 
  .beneficios {
    margin-top: -100px;}
}

@media (max-width: 500px){
 
  .text-home{
    padding-top: 130px;
    font-size: 25px;
  }
  .btn-hero{
  margin-top: 30px;
  margin-left: 30px;
  }
  .cont-img-two{
  margin-bottom: -200px;
    padding-top: 30px;
    width: 100%;
  
  }
  .cont-text-two{
    padding-right: 40px;
    padding-top: 120px;
  }
  .cont-text-two h1{
    font-size: 20px;
  }
  .cont-text-two .parrafo{
    font-size: 15px;}
    .beneficios {
      margin-top: -40px;
  }
}

@media (max-width: 500px) {
  .text-home {
    padding-top: 130px;
    font-size: 25px;
  }
  .btn-hero {
    margin-top: 30px;
    margin-left: 30px;
  }
  .cont-img-two {
    margin-top: 170px;
    padding-top: 30px;
    width: 100%;
    min-height: 500px;
    background-attachment: scroll;
  }
  .cont-text-two {
    padding-right: 40px;
    padding-top: 120px;
  }
  .cont-text-two h1 {
    font-size: 20px;
  }
  .cont-text-two .parrafo {
    font-size: 15px;
  }
  .grid-fotos{
    grid-template-columns: 12fr
  }
  .beneficios {
    margin-top: -40px;
}
}

@media  (max-width: 430px){
  
  .cont-img-two{
    display: none; 
  }
  .cont-hero-two{
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .beneficios{
    margin-top: 50px;
  }

  .tarjetas{
    gap: 0px;
  }
  .lineas{
    margin-top: 117px;
  }
}

@media  (max-width: 250px){

.cont-hero-two {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
}
}

@media (max-width: 720px){

  .tituloLineas{
    margin-top: -514px;
  }
} 

@media (max-width: 513px){
  .beneficios{
    margin-top: -50px;
  }
  .tituloLineas{
    margin-top: 0px;
  }
} 

@media (max-width: 414px){
  .cont-hero-two{
    margin-bottom: 80px;
  }
} 

@media (max-width: 280px) {
  .tarjetas{
    align-items: center;
  }
  .divTarjeta{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cont-hero-two{
    margin-left: 40px;
  }

}